<template>
  <div class="analysis-detail-data" v-loading.fullscreen.lock="loading"
    :element-loading-text="$t('common.preparingData')">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="search-content">
      <el-form @submit.native.prevent :inline="true" size="small" class="search-form">
        <el-form-item class="search-form-gutter" :label="$t('analysis.platform')">
          <el-select style="width: 88px" v-model="platform" @change="changePlatform">
            <el-option v-for="item in platformList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-input v-model="date"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="fetchData">{{
            $t("common.search")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="preview" :style="{ 'min-height': previewLoading ? '100px' : 0 }" v-loading="previewLoading">
      <el-row :gutter="10">
        <el-col :span="12" v-for="t in primaryData" :key="t.date">
          <el-card shadow="never">
            <div slot="header" class="">
              <span class="preview-title">{{ t.linkDate }}</span>
              <el-button icon="el-icon-download" class="preview-download excel-dl-btn" size="mini"
                @click="handlePreviewDownload(t.date)">{{ $t("common.download") }}</el-button>
            </div>
            <el-table size="mini" :stripe="true" :border="true" :data="analysisType.key === 'sales'
              ? t.sales
              : analysisType.key === 'stock'
                ? t.stock
                : []
              " :key="t.date">
              <el-table-column prop="brandName" min-width="70">
                <template slot-scope="scope">
                  <el-image class="brand-img" :src="scope.row.iconImageUrl | imageUrl" fit="contain" />
                </template>
              </el-table-column>
              <el-table-column min-width="60" :label="$t('data.sumStyle')">
                <template slot-scope="scope">{{
                  scope.row.sumStyle.toLocaleString()
                }}</template>
              </el-table-column>
              <el-table-column v-if="analysisType.key === 'stock'" min-width="60" :label="$t('data.sumStock')"><template
                  slot-scope="scope">{{
                    scope.row.sumStock.toLocaleString()
                  }}</template></el-table-column>
              <el-table-column v-if="analysisType.key === 'stock'" prop="colorAvg" min-width="60"
                :label="$t('data.colorAvg')"></el-table-column>
              <el-table-column v-if="analysisType.key === 'stock'" prop="sizeAvg" min-width="60"
                :label="$t('data.sizeAvg')"></el-table-column>
              <el-table-column min-width="60" :label="$t('data.sumMonthSale')"><template slot-scope="scope">{{
                scope.row.sumMonthSale.toLocaleString()
              }}</template></el-table-column>
              <el-table-column v-if="analysisType.key === 'stock'" prop="saleStockPercentage" min-width="60"
                :label="$t('data.saleStockPercentage')"></el-table-column>
              <el-table-column v-if="analysisType.key === 'sales'" min-width="60"
                :label="$t('data.newProducts')"><template slot-scope="scope">{{
                  scope.row.newProducts.toLocaleString()
                }}</template></el-table-column>
              <el-table-column v-if="analysisType.key === 'sales'" min-width="60"
                :label="$t('data.repeatProducts')"><template slot-scope="scope">{{
                  scope.row.repeatProducts.toLocaleString()
                }}</template></el-table-column>
              <el-table-column v-if="analysisType.key === 'sales'" min-width="60"
                :label="$t('data.currentYearProducts')"><template slot-scope="scope">{{
                  scope.row.currentYearProducts.toLocaleString()
                }}</template></el-table-column>
              <el-table-column v-if="analysisType.key === 'sales'" min-width="60"
                :label="$t('data.previousYearProducts')"><template slot-scope="scope">{{
                  scope.row.previousYearProducts.toLocaleString()
                }}</template></el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" row-key="id" style="width: 100%">
      <el-table-column prop="linkDate" :label="$t('analysis.date')"></el-table-column>
      <el-table-column :label="$t('common.action')" min-width="70">
        <template slot-scope="scope">
          <el-button class="export-btn" type="warning" icon="el-icon-files" size="small" @click="exportData(scope.row)">{{
            $t("common.export") }}</el-button>
          <a v-if="scope.row.url" :href="scope.row.url | ossUrl">
            <el-button class="excel-dl-btn" icon="el-icon-download" size="small">{{ $t("common.download") }}</el-button>
          </a>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
  </div>
</template>

<script>
import {
  fetchBasicReport,
  exportBasicReport,
  fetchBasicPrimary,
} from "@/api/analysis";
import { downloadFileAuth } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";
import Guide from "@/components/Guide";

export default {
  components: { AiBreadcrumb, Pagination, Guide },
  data() {
    return {
      platform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      date: "",
      options: [],
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      timer: null,
      loading: false,
      previewLoading: false,
      primaryData: [],
    };
  },
  created() {
    this.fetchData();
    this.fetchPrimary();
  },
  methods: {
    fetchData() {
      fetchBasicReport({
        ...this.listQuery,
        type: this.analysisType.key,
        period: "week",
        platform: this.platform,
        q: this.date,
      }).then((response) => {
        this.tableData = response.result.items || [];
        this.total = response.result.totalCount;
      });
    },
    fetchPrimary() {
      this.previewLoading = true;
      fetchBasicPrimary(this.analysisType.key, {
        period: "week",
        platform: this.platform,
      })
        .then((response) => {
          if (response.success) {
            this.primaryData = response.result || [];
          }
        })
        .finally(() => {
          this.previewLoading = false;
        });
    },
    exportData(row) {
      let doExport = () => {
        this.loading = true;
        let cb = (response) => {
          if (response.success) {
            this.timer = setTimeout(() => {
              this.fetchUrl(row.date);
            }, 4000);
          }
        };
        exportBasicReport(this.analysisType.key, {
          date: row.date,
          period: "week",
        }).then((response) => {
          cb(response);
        });
      };
      if (row.url) {
        this.$confirm(
          this.$t("analysis.reportRegenerate"),
          this.$t("common.pleaseConfirm"),
          {
            type: "warning",
          }
        ).then(() => {
          doExport();
        });
      } else {
        doExport();
      }
    },
    fetchUrl(date) {
      this.checkExist(date, (url) => {
        if (url) {
          clearInterval(this.timer);
          this.loading = false;
          this.fetchData();
        } else {
          this.timer = setTimeout(() => {
            this.fetchUrl(date);
          }, 2000);
        }
      });
    },
    checkExist(date, cb) {
      fetchBasicReport({
        type: this.analysisType.key,
        period: "week",
        dates: date,
        limit: 1,
      }).then((response) => {
        let url =
          response.result.items && response.result.items.length > 0
            ? response.result.items[0].url
            : "";
        if (cb) {
          cb(url);
        }
      });
    },
    handlePreviewDownload(date) {
      let username =
        this.$store.getters.my && this.$store.getters.my.userName
          ? this.$store.getters.my.userName
          : "";
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      downloadFileAuth(
        aiApiRoot +
        "/v1/analysis/basic/" +
        this.analysisType.key +
        "/primary/export?period=week&date=" +
        date,
        this.analysisType.key + "_data_" + date + "_" + username + ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    changePlatform() {
      this.fetchData();
      this.fetchPrimary();
    },
  },
  computed: {
    analysisType() {
      if (this.$route.meta && this.$route.meta.key === "stock") {
        return { name: "Stock", key: "stock" };
      }
      return { name: "Sales", key: "sales" };
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-detail-data {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .preview {
    margin-bottom: 10px;
    text-align: center;

    .preview-title {
      line-height: 27.5px;
    }

    .preview-download {
      right: 0;
      position: absolute;
    }

    .el-card {
      border: none;
      border-radius: 0;
    }

    /deep/ .el-card__header {
      padding: 5px;
      font-weight: bold;
      border: none;

      div {
        position: relative;
      }
    }

    /deep/ .el-card__body {
      padding: 0;
      font-size: 12px;
    }

    /deep/ .el-table {
      border-left: none;
    }

    /deep/ .el-table__header-wrapper th:nth-last-of-type(2) {
      border-right: none;
    }

    /deep/ .el-table--border td:nth-last-of-type(1) {
      border-right: none;
    }

    /deep/ .el-table--border::after,
    .el-table--group::after {
      width: 0;
    }

    /deep/ .el-table .cell {
      padding-left: 5px;
      padding-right: 5px;
    }

    /deep/ .el-table--mini td {
      padding: 2px 0;
    }

    .el-image {
      max-height: 200px;
      vertical-align: middle;
    }

    .brand-img {
      width: 100%;
      height: 0;
      padding: 0;
      padding-bottom: 60%;

      /deep/ img {
        position: absolute;
      }

      /deep/ .el-image__error {
        position: absolute;
      }
    }
  }

  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 30px;
      }
    }
  }

  .export-btn {
    margin-right: 10px;
  }
}
</style>